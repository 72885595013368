<template>
  <b-container
    class="mt-2"
    fluid
  >
    <!-- Filters -->
    <b-row>
      <b-col
        cols="12"
        class="px-1"
      >
        <b-form-group
          label="Search"
          label-for="filterPendingSearch"
          label-class="font-weight-bolder"
        >
          <b-input
            id="filterPendingSearch"
            v-model="tablePending.filter.search"
            placeholder="search here"
            autocomplete="off"
            debounce="1000"
            type="text"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Table -->
    <b-row
      v-if="Number(tabIndex) === 0"
      class="mt-1"
    >
      <b-col
        cols="12"
        class="px-1"
      >
        <b-table
          ref="tablePending"
          small
          hover
          striped
          bordered
          responsive
          show-empty
          :items="tableProvider"
          :busy="tablePending.busy"
          :filter="tablePending.filter"
          :fields="tablePending.fields"
          :stacked="tablePending.stacked"
          :sort-by.sync="tablePending.sortBy"
          :sort-desc.sync="tablePending.sortDesc"
          :sort-direction="tablePending.sortDirection"
          :filter-included-fields="tablePending.filterOn"
          :current-page="tablePending.currentPage"
          :per-page="tablePending.perPage"
        >
          <template #cell(index)="{ index }">
            {{ tableRowNumber(tablePending, index) }}
          </template>

          <template #cell(action)="{ item }">
            <div class="text-md-nowrap d-flex flex-column flex-md-row justify-content-center">
              <b-button
                size="sm"
                variant="primary"
                class="mr-0 mr-md-1 mb-1 mb-md-0"
                :disabled="tablePending.busy"
                @click.prevent="show(item)"
              >
                Process
              </b-button>
            </div>
          </template>

          <template #cell()="{ value }">
            <div
              class="text-md-nowrap"
              v-text="value"
            />
          </template>

          <template #cell(approvable.purchase_number)="{ value }">
            <b
              class="text-md-nowrap"
              v-text="value"
            />
          </template>

          <template #cell(approvable.document_number)="{ value }">
            <b
              class="text-md-nowrap"
              v-text="value"
            />
          </template>

          <template #cell(approvable.transfer_number)="{ value }">
            <b
              class="text-md-nowrap"
              v-text="value"
            />
          </template>

          <template #table-busy>
            <div class="text-center py-5">
              <b-icon
                icon="stopwatch"
                font-scale="5"
                animation="cylon"
              />
              <p class="h3 py-2">
                <strong>Loading . . .</strong>
              </p>
            </div>
          </template>

        </b-table>
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="mt-1 mb-2 px-1"
      >
        <b-select
          v-model="tablePending.perPage"
          :options="tablePending.pageOptions"
          :disabled="tablePending.busy"
          class="w-100 w-md-25"
          size="sm"
        />
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="d-flex justify-content-center justify-content-md-end mt-1 px-1"
      >
        <b-pagination
          v-model="tablePending.currentPage"
          :total-rows="tablePending.totalRows"
          :per-page="tablePending.perPage"
          :disabled="tablePending.busy"
          aria-controls="table"
          prev-text="Prev"
          next-text="Next"
          first-number
          last-number
          pills
        />
      </b-col>

    </b-row>
  </b-container>
</template>
<script>
import MISC from '@/mixins/misc'
import FORMATTER from '@/mixins/formatter'
import { SUserPosting } from '@/services'

export default {

  name: 'UserPostingPending',

  mixins: [
    MISC,
    FORMATTER
  ],

  props: {
    tabIndex: {
      type: Number,
      default: () => 0
    }
  },

  data () {
    return {
      tablePending: {
        busy: false,
        filter: {
          search: null
        },
        filterOn: [],
        stacked: this.isMobile(),
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        pageOptions: [10, 25, 50, 100],
        currentPage: 1,
        totalRows: 0,
        perPage: 10,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 10, key: 'action', class: 'text-center' },
          { mobile: 1, key: 'created_at', label: 'Requested At', class: 'min-w-175px', formatter: this.dateTimeShortFormatter, sortable: true },
          { mobile: 2, key: 'approvable.purchase_number', label: 'PO Number', class: 'text-center min-w-100px', sortable: true },
          { mobile: 3, key: 'approvable.document_number', label: 'Document Number', class: 'text-center min-w-100px', sortable: true },
          { mobile: 4, key: 'approvable.transfer_number', label: 'Transfer Number', class: 'text-center min-w-100px', sortable: true },
          { mobile: 5, key: 'approvable.destination_name', label: 'Requested By', class: 'min-w-200px' },
          { mobile: 6, key: 'approvable.group_name', label: 'Group', class: 'min-w-200px' },
          { mobile: 7, key: 'approvable.transfer_details.length', label: 'Products', class: 'text-center' },
          { mobile: 8, key: 'approvable.delivery_details.customer', label: 'Customer', class: 'min-w-200px' },
          { mobile: 9, key: 'approvable.customer_direct', label: 'D2C', class: 'text-center', formatter: this.yesOrNo }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  methods: {

    async tableProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tablePending.busy = true
      return await SUserPosting.get({
        page: currentPage,
        per_page: perPage,
        sort: sortBy,
        sort_desc: sortDesc,
        filter_text: filter.search
      }).then(({ data }) => {
        this.tablePending.totalRows = data.total_rows
        return data.items
      }).catch(() => {
        this.tablePending.totalRows = 0
        return []
      }).finally(() => {
        this.tablePending.busy = false
      })
    },

    tableRefresh () {
      this.$refs.tablePending.refresh()
    },

    show (stockRequest) {
      this.$emit('show', stockRequest, true)
    },

    async update (payload) {
      return new Promise((resolve, reject) => {
        SUserPosting.put(payload).then(
          ({ data: { message, transfer } }) => {
            this.swalSuccess(message)
            resolve({ message, transfer })
          }
        ).catch(
          ({ message }) => reject(message)
        )
      })
    }

  }
}
</script>
